import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import TestimonialSection from "../components/testimonials-section"
import Cta from "../components/cta"
import Seo from "../components/seo"
import scrollTo from 'gatsby-plugin-smoothscroll'
import ArrowRight from "../components/svg/arrowRight"

const StartupSeoPage = () => (
    <Layout>
        <Seo 
            title="SEO for early stage startups in Australia" 
            description="Launch Lab assists early stage startups with search engine optimisation (SEO) and getting the right SEO foundations in place."
            pathname="/seo-for-startups/"
        />

        <section role="main" className="bg-purple with-pad-sm white">
          <div className="container">
              <div className="row flex flex-jc">
                  <div className="hero-content sub-hero">
                      <h1
                      className="small-h"
                      data-sal="slide-up" 
                      data-sal-easing="ease"
                      data-sal-delay="300"
                      data-sal-duration="400"
                      >SEO For Startups</h1>
                      <h2 className="hero-lead"
                      data-sal="slide-up" 
                      data-sal-easing="ease"
                      data-sal-delay="400"
                      data-sal-duration="400"
                      >
                          We offer search engine optimisation services to early stage startups in Sydney and throughout Australia
                      </h2>
                      <div className="btn-row">
                      <button onClick={() => scrollTo('#anchor')} className="btn btn-lg btn-pink">
                          <span className="flex flex-ac down">
                          <ArrowRight />
                          </span>
                      </button>
                      </div>
                  </div>
                </div>
            </div>
        </section>

        <section className="section-pad" id="anchor">
            <div className="container">
                <div className="row">
                    <div className="cols">
                        <div className="col-26">
                            <h3>Launch your startup with the right SEO foundations</h3>
                        </div>

                        <div className="col-67 long-text">
                            <p>
                                First and foremost Launch Lab are <Link to="/startup-web-application-development/">startup application developers</Link>.
                            </p>
                            <p>
                                We never set out to position ourselves as <strong>SEO experts</strong> in Australia but in developing startups we've gained a lot of experience advising early stage businesses on Search Engine Optimisation best practice prior to their initial website launch.
                            </p>
                            <p>Our SEO services include:</p>
                            <ul>
                                <li>Keyword research and competitor analysis</li>
                                <li>Information architecture</li>
                                <li>Technical / on-page SEO</li>
                                <li>Blogging and content strategy</li>
                            </ul>
                            <p>
                                If you are working on an early stage startup and think that SEO should be part of your digital strategy, keep in mind that <Link to="/sem-vs-seo-strategy/">SEO is a long term strategy</Link> and requires effort on your part too (for example, we don't offer offsite link building as a service). 
                            </p>
                            <p>
                                SEO also wouldn't be a good investment for some early stage startups, however for others it will be imperative to rank highly in search engine results as quickly as possible for certain keywords.
                            </p>
                            <p>
                                If you are an Australian startup founder and would like to discuss search engine optimisation or digital marketing, and how Launch Lab can help, please contact us.
                            </p>
                            <p>
                                It's important to note that we aren't an SEO agency. We offer <strong>on-page SEO services</strong> to early stage startups who are just getting started.
                            </p>
                            <div className="btn-row">
                                <Link to="/contact/" className="btn btn-lg btn-purple">
                                    <span className="flex flex-ac">
                                        Contact us
                                    <ArrowRight />
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <TestimonialSection />

        <Cta 
            text="Let's chat about your startups launch strategy and the related SEO requirements for your website."
        />
    </Layout>
)

export default StartupSeoPage